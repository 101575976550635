import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Login } from "./Login";
import { AppWrapper } from "./AppWrapper";
import "./firebase/firebase";
import { Routes } from "../Routes";

function App() {
  const appRouter = createBrowserRouter([
    {
      path: "/login",
      element: <Login title="Minipad" />,
    },
    {
      path: "",
      element: <AppWrapper />,
      children: Routes,
    },
  ]);

  return <RouterProvider router={appRouter} />;
}

export default App;
