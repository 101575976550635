import styles from "./Logo.module.scss";

import { ReactComponent as LogoIcon } from "./logo.svg";

type LogoProps = {
  icon?: boolean;
  color?: string;
};

export const Logo = ({ icon, color }: LogoProps) => {
  return (
    <div className={styles.Logo} style={{ color }}>
      {icon && <LogoIcon width="24px" height="24px" />}
      minipad
    </div>
  );
};
