import debounce from "lodash.debounce";
import { JSONContent } from "@tiptap/react";

import { doc, setDoc, getDoc } from "firebase/firestore";
import { getCurrentUser } from "../skeleton/firebase/auth";
import { db } from "../skeleton/firebase/firebase";

export const saveContent = debounce(
  async (id: string, content: JSONContent, callback?: () => void) => {
    const userId = getCurrentUser()?.uid;
    if (!userId) throw new Error("No user id");

    await setDoc(doc(db, "users", userId, "memos", id), content);
    callback && callback();
  },
  1000,
  {
    leading: false,
    trailing: true,
  },
);

export const loadContent = async (id: string): Promise<JSONContent> => {
  const userId = getCurrentUser()?.uid;
  if (!userId) throw new Error("No user id");

  const docRef = doc(db, "users", userId, "memos", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as JSONContent;
  } else {
    return {
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Hello World!",
            },
          ],
        },
      ],
    };
  }
};
