import {
  FloatingMenu,
  BubbleMenu,
  useEditor,
  EditorContent,
} from "@tiptap/react";

import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Placeholder from "@tiptap/extension-placeholder";

import { Check, Bold, UploadCloud } from "react-feather";

import "./Editor.css";
import { loadContent, saveContent } from "./data/ContentStore";
import { useEffect, useState } from "react";
import { LoadingIndicator } from "./skeleton/LoadingIndicator";

const CustomDocument = Document.extend({
  content: "heading block*",
});

const extensions = [
  CustomDocument,
  StarterKit.configure({ document: false }),
  Placeholder.configure({
    placeholder: ({ node }) => {
      if (node.type.name === "heading") {
        return "What’s this memo about?";
      }
      return "";
    },
  }),
];

export const Editor = ({ memoId }: { memoId: string }) => {
  const [contentReady, setContentReady] = useState(false);
  const [upToDate, setUpToDate] = useState(true);

  const editor = useEditor({
    extensions,
    content: "",
    onUpdate: ({ editor }) => {
      setUpToDate(false);
      saveContent(memoId, editor.getJSON(), () => setUpToDate(true));
    },
  });

  useEffect(() => {
    if (!!editor) {
      loadContent(memoId)
        .then((content) => {
          editor!.commands.setContent(content);
          setContentReady(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [editor, memoId]);

  if (!contentReady) return <LoadingIndicator globalCenter />;

  return (
    <div className="Editor">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "absolute",
          right: "24px",
          top: "24px",
        }}
      >
        {upToDate ? (
          <div title="All changes saved">
            <Check size={20} color="#ccc" />
          </div>
        ) : (
          <div title="Saving changes...">
            <UploadCloud size={20} color="#ccc" />
          </div>
        )}
      </div>
      <EditorContent editor={editor} style={{ minHeight: "80vh" }} />
      <FloatingMenu editor={editor ?? undefined}>
        <div />
      </FloatingMenu>
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            type="button"
            className="rounded px-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            <Bold size={16} />
          </button>
        </BubbleMenu>
      )}
    </div>
  );
};
