import { Header } from "./skeleton/Header";
import { Editor } from "./Editor";
import { Logo } from "./components";
import { logout } from "./skeleton/firebase/auth";
import { useNavigate, useParams } from "react-router-dom";

const MemoRoute = () => {
  const navigate = useNavigate();
  const { memoId } = useParams();

  return (
    <div>
      <Header
        left={<Logo icon color="#ccc" />}
        right={
          <button
            className="text-gray-300 font-bold text-sm"
            onClick={() => logout().then(() => navigate("/login"))}
          >
            Sign out
          </button>
        }
      />
      <Editor memoId={memoId ?? "root"} />
    </div>
  );
};

export const Routes = [
  {
    path: "/",
    element: <MemoRoute />,
  },
  {
    path: "/:memoId",
    element: <MemoRoute />,
  },
];
