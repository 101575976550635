import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB5EWCFRvRnBTDQIaxUQZ58DFCPWy4Z3yk",
  authDomain: "simplipad-ef2da.firebaseapp.com",
  projectId: "simplipad-ef2da",
  storageBucket: "simplipad-ef2da.appspot.com",
  messagingSenderId: "1004155638976",
  appId: "1:1004155638976:web:66f6f40265fd404d5bc925",
};

export const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);
