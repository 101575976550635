import React, { useEffect, useState } from "react";
import { LoadingIndicator } from "./LoadingIndicator";
import { Outlet, useNavigate } from "react-router-dom";
import { AppUser, waitForUserLoading } from "./firebase/auth";

export const AppWrapper = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState<AppUser | null>(null);

  useEffect(() => {
    waitForUserLoading()
      .then((user) => {
        if (!user) navigate("/login");
        else setUser(user);
      })
      .catch(() => {
        navigate("/login");
      });
  }, [navigate, user]);

  if (user === null) return <LoadingIndicator globalCenter />;
  return <Outlet />;
};
