import styles from "./LoadingIndicator.module.scss";
import cx from "classnames";

type LoadingIndicatorProps = {
  globalCenter?: boolean;
};

export const LoadingIndicator = ({ globalCenter }: LoadingIndicatorProps) => (
  <div
    className={cx({
      [styles.globalCenter]: globalCenter,
    })}
  >
    <div className={cx(styles["lds-ripple"])}>
      <div></div>
      <div></div>
    </div>
  </div>
);
