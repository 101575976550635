import { ComponentProps } from "react";
import cx from "classnames";
import styles from "./SignInButton.module.scss";

export const SignInButton = ({
  className,
  ...rest
}: ComponentProps<"button">) => {
  return <button className={cx(className, styles.SignInButton)} {...rest} />;
};
