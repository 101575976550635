import { signInWithGoogle } from "../firebase/auth";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import { SignInButton } from "./SignInButton";

import { ReactComponent as GoogleIcon } from "./google.svg";
import { Logo } from "../../components";
import { Header } from "../Header";

type LoginProps = {
  title: string;
};

export const Login = ({ title }: LoginProps) => {
  const navigate = useNavigate();

  const login = useCallback(() => {
    signInWithGoogle().then(() => navigate("/"));
  }, [navigate]);

  return (
    <div className={styles.Root}>
      <Header left={<Logo icon />} />
      <div className={styles.Container}>
        <div className={styles.Card}>
          <h1
            style={{
              alignSelf: "center",
              fontFamily: "Poppins",
              color: "#AC87C5",
            }}
          >
            {title}
          </h1>
          <p style={{ whiteSpace: "pre-line", marginBottom: "32px" }}>
            {"Hello! 👋\nLet's get you signed in."}
          </p>
          <SignInButton onClick={login} style={{ width: "100%" }}>
            <GoogleIcon style={{ width: "24px", height: "24px" }} />
            Sign In with Google
          </SignInButton>
        </div>
      </div>
    </div>
  );
};
