import styles from "./Header.module.scss";
import { ReactNode } from "react";

type HeaderProps = {
  left?: ReactNode;
  right?: ReactNode;
};
export const Header = ({ left, right }: HeaderProps) => (
  <div className={styles.Header}>
    {left}
    {right}
  </div>
);
